<template>
	<standard-container title="audits" description="" menuicon="audits">
		<!-- header -->
		<template v-slot:header>
		</template>

		<!-- body slot -->
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-row :dense="compactVisualization">
					<v-col cols="12" md="6">
						<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" label="Ricerca" single-line hide-details clear-icon="mdi-close-circle" clearable />
					</v-col>
				</v-row>
				<v-data-table 
					class="mt-6"
					:items="audits" 
					:headers="gTranslateTableHeader(tableHeaders)"
					:search="search" 
					:sort-by="['DataCreazione']" 
					:sort-desc="[true]"
					:page.sync="page"
					:items-per-page="itemsPerPage"
					item-key="Id"
					hide-default-footer
					@click:row="editItem"
					@page-count="pageCount = $event"
				>
					<template v-slot:item.DataCreazione="{ item }">
						{{ gStandardDate(item.DataCreazione) }}
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		
		<!-- footer -->
		<template v-slot:footer>
			<v-row class="mt-1">
				<v-col cols="6" sm="2" order="2" order-sm="1">
					<v-btn small class="mt-3" icon title="Ricarica la lista" :disabled="loading" :loading="loading" @click="refreshList">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="12" sm="8" order="1" order-sm="2">
					<v-pagination v-model="page" circle color="warning" :length="pageCount" :disabled="itemsPerPage === -1" />
				</v-col>
				<v-col cols="6" sm="2" order="3" order-sm="3">
					<div class="d-flex justify-end">
						<div style="max-width:100px">
							<v-select v-model="itemsPerPage" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :items="itemsPerPageOptions" label="Righe" />
						</div>
					</div>
				</v-col>
			</v-row>
		</template>

		<!-- debug -->
		<template v-slot:debug>
			<v-row>
				<v-col cols="12" md="6">
					<debug-panel label="audits">
						{{ audits }}
					</debug-panel>
				</v-col>
			</v-row>
		</template>

		<!-- inizio dialog -->
		<v-dialog v-if="currentAudit" v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title v-text="(currentAudit.Id<0 ? 'Nuovo' : 'Modifica') + ' audit'" />
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="!currentAudit.Titolo || loading" :loading="loading" @click="updateItem">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row :dense="compactVisualization">
						<v-col cols="12" lg="12">
							<span class="headline">{{ currentPersonaFullName }}</span>
						</v-col>
					</v-row>
					<v-row :dense="compactVisualization">
						<v-col cols="12" lg="6">
							<v-text-field ref="autofocus" v-model="currentAudit.Titolo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Titolo" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-select v-model="currentAudit.IdColloquio" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Tipo colloquio" :items="decodifiche.TipoColloquio" item-value="Id" item-text="TipoColloquio" @change="loadTipologiaColloquio(currentAudit.IdColloquio)" />
						</v-col>
						<v-col cols="12" lg="6">
							<hr-date-picker v-model="currentAudit.DataCreazione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Data" />
						</v-col>
						<v-col cols="12" lg="6">
							<!-- <v-autocomplete v-model="currentAudit.IdUtenteReferenteColloquio" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Persona che ha gestito il colloquio" :items="persone" item-value="Id" item-text="FullName" /> -->
							<v-text-field v-model="currentAudit.ReferenteExt" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Utente che ha gestito il colloquio')" :disabled="true" />
						</v-col>
						<template v-if="currentColloquio">
							<v-col cols="12">
								<div class="d-flex align-center">
									<span class="title mr-5 primary--text">Scopo colloquio</span><v-divider />
								</div>
								<p class="caption mb-1 text-right grey--text" v-text="currentColloquio.ScopiObiettivi" />
								<v-textarea v-model="currentAudit.NoteScopoColloquio" single-line :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" rows="1" auto-grow label="Note scopo colloquio" />
							</v-col>
							<v-col cols="12">
								<div class="d-flex align-center">
									<span class="title mr-5 primary--text">Svolgimento</span><v-divider />
								</div>
								<p class="caption mb-1 text-right grey--text" v-text="currentColloquio.Svolgimento" />
								<v-textarea v-model="currentAudit.NoteSvolgimentoColloquio" single-line :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" rows="1" auto-grow label="Note svolgimento" />
							</v-col>
							<v-col cols="12">
								<div class="d-flex align-center">
									<span class="title mr-5 primary--text">Conclusioni</span><v-divider />
								</div>
								<p class="caption mb-1 text-right grey--text" v-text="currentColloquio.Conclusioni" />
								<v-textarea v-model="currentAudit.NoteConclusioneColloquio" single-line :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" rows="1" auto-grow label="Note conclusioni" />
							</v-col>
							<v-col cols="12">
								<div class="d-flex align-center mb-5">
									<span class="title mr-5 warning--text">Attenzione</span><v-divider />
								</div>
								<v-textarea v-model="currentAudit.NoteAttenzione" single-line :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" rows="1" auto-grow label="Attenzione" />
							</v-col>
						</template>
					</v-row>

					<debug-container>
						<v-row :dense="compactVisualization">
							<v-col cols="12" lg="6">
								<debug-panel label="Current Audit">
									{{ currentAudit }}
								</debug-panel>
							</v-col>
							<v-col cols="12" lg="6">
								<debug-panel label="Current Colloquio">
									{{ currentColloquio }}
								</debug-panel>
							</v-col>
							<v-col cols="6">
								<debug-panel label="this.decodifiche.NomiUtenti">
									{{ decodifiche.NomiUtenti }}
								</debug-panel>
							</v-col>							
						</v-row>
					</debug-container>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->			
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
import DebugContainer from '@/components/debug-container'
import DebugPanel from '@/components/debug-panel'
import HrDatePicker from '@/components/hr-date-picker'
export default {
	name: 'AuditsPage',
	components: { StandardContainer, DebugContainer, DebugPanel, HrDatePicker },
	data: () => ({
		currentTab:null,
		dialog: false,
		loading: false,
		search: '',
		page: 1,
		pageCount: 0,
		itemsPerPage: 20,
		audits: [],
		currentAudit: {},
		currentColloquio: {},
		currentPersonaFullName: '',
		itemsPerPageOptions: [{text:'5', value:5},{text:'10', value:10},{text:'20', value:20},{text:'50', value:50},{text:'100', value:100},{text:'Tutti', value:-1}],	
		tableHeaders: [
			{ text: 'Data', value: 'DataCreazione'},
			{ text: 'Titolo', value: 'Titolo' },
			{ text: 'Persona', value: 'PersonaExt' },
			{ text: 'Tipo colloquio', value: 'DescrizioneTipoAudit' },
			{ text: 'Referente', value: 'ReferenteExt' },
		],
	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		persone() { return this.$store.getters['persone'] },
	},
	mounted() {
		this.$refs.searchField.focus()
		this.initData()
	},
	methods: {
		// carica i dati dallo store (disacoppiandoli) e poi cicla la tabella risultante per caricare le decodifiche necessarie
		initData() {
			this.$set(this, 'audits', JSON.parse(JSON.stringify(this.$store.getters['audits'])))
			this.audits.forEach(item => {
				this.$set(item, 'DescrizioneTipoAudit', this.decodificaDescrizioneTipoColloquio(item.CodiceTipoAudit))
				this.$set(item, 'PersonaExt', this.decodificaFullNamePersona(item.IdPersona))
				
				// attenzione che si tocca questa funzione va aggiornata anche in hr-persona-audits.vue e viceversa
				// decodifica nel campo preesistente ReferenteExt
				// se ReferenteColloquio è uguale a -1 il record è nuovo e quindi viene decodificato il nome dell'utente (non persona) a partire dal campo IdUtenteReferenteColloquio
				// se invece è maggiore di 0 significa che il record è vecchio e il nome va preso decodificando ReferenteColloquio sulle persone (non sugli utenti)
				// infine se ReferenteColloquio==0 non possiamo decodificare una beata fava :-)
				var nomeReferenteColloquio = '*'
				if(item.ReferenteColloquio===-1) {
					var dummy1 = this.decodifiche.NomiUtenti.find(subItem => { return subItem.Id === item.IdUtenteReferenteColloquio })
					nomeReferenteColloquio = dummy1 ? dummy1.FullName : '- u:' + item.IdUtenteReferenteColloquio + '-'
				} else if(item.ReferenteColloquio > 0) {
					var dummy2 = this.persone.find(subItem => { return subItem.Id === item.ReferenteColloquio })
					nomeReferenteColloquio = dummy2 ? dummy2.FullName : '- p:' + item.ReferenteColloquio + '-'
				} else {
					nomeReferenteColloquio = '--'
				}
				this.$set(item, 'ReferenteExt', nomeReferenteColloquio)

				//this.$set(item, 'ReferenteExt', this.decodificaFullNamePersona(item.ReferenteColloquio))
			})
		},
		//
		editItem(item) {
			this.$set(this, 'currentAudit', JSON.parse(JSON.stringify(item))) // disaccoppia e carica il record selezionato come current
			// il seguente passaggio è apparentemente inutile, in realtà è pensato per la futura unificazione con il componente "hr-persona-audits", vedi #830
			this.currentPersonaFullName = item.PersonaExt // todo:rifare comunque con il sistema di inclusione tipo initData così da avere tutto in un unica "currentXXX"
			this.loadTipologiaColloquio(item.IdColloquio) // inizializza anche currentColloquio
			this.dialog = true
		},
		// decodifica descrizione tipo colloquio
		decodificaDescrizioneTipoColloquio(id){
			if(!this.decodifiche.TipoColloquio) return
			var dummy = this.decodifiche.TipoColloquio.find(item => { return item.Id == id })
			if(dummy) return dummy.TipoColloquio
			return null
		},
		// decodifica il nome di una persona
		decodificaFullNamePersona(id) {
			var dummy = this.persone.find(item => { return item.Id === id })
			if(dummy) return dummy.FullName
			return '-'
		},
		// carica in currentColloquio la tipologia colloquio richiesta
		loadTipologiaColloquio(id) {
			if(!this.decodifiche.TipoColloquio) return
			var dummy = this.decodifiche.TipoColloquio.find(item => { return item.Id == id })
			if(!dummy) dummy = this.decodifiche.TipoColloquio[0] // se non trova l'id richiesto carica il primo della lista
			this.currentColloquio = dummy
		},
		// salva un record di audit
		updateItem() {
			this.loading = true
			/* copio l'IdColloquio corrente nel vecchio campo CodiceTipoAudit 
			 ricordo che "CodiceTipoAudit" è un vecchio campo string mantenuto per compatibilità 
			 mentre "IdColloquio" è un int not-mapped generato al volo per velocizzare le operazioni 
			 In fase di salvataggio però conta solo il vecchio campo */
			this.currentAudit.CodiceTipoAudit = '' + this.currentAudit.IdColloquio
			this.$store.dispatch('saveAudit', this.currentAudit).then(()=>{
				this.initData() // dopo che lo store ha ricaricato i dati la init provvede a prepararli per l'uso
				this.loading = false
				this.dialog = false
			})
		},
		// forza il refresh dei dati
		refreshList() {
			this.loading = true
			this.$store.dispatch('refreshAudits').then(()=>{
				this.initData() // dopo che lo store ha ricaricato i dati la init provvede a prepararli per l'uso
				this.loading = false
			})
		},
	}
}
</script>
